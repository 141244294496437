import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Stack,
  Heading,
  Text,
  HStack,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import gradientf from "../home/hpics/gradientf.png";
function MyAccordion() {
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("#f2ede7", "#000");
  return (
    <VStack
      id="Resume"
      spacing={4}
      align="center"
      p={4}
      pt={10}
      bgColor={bgColor}
    >
      <Heading
        mt={10}
        style={{
          // fontFamily: 'Falcon Pro',
          letterSpacing: "2px",
          fontWeight: "bolder",
          fontSize: "45px",
        }}
      >
        RESUME
      </Heading>
      <Text style={{}}>
        <span
          style={{
            color: "#29A587",
            fontFamily: "Falcon Pro",
            fontSize: "16px",
            fontWeight: "bolder",
          }}
        >
          MY
        </span>{" "}
        <span
          style={{
            fontFamily: "Caveat",
            fontStyle: "cursive",
            fontSize: "30px",
            fontWeight: "bolder",
          }}
        >
          Story
        </span>
      </Text>
      <Stack
        direction={{ base: "column", md: "row" }}
        mt="10px"
        maxW="1000px"
        // _dark={{
        //   bg: "gray.800",
        // }}
        _hover="none"
      
        
        spacing={4}
        align="stretch"
      >
        <Accordion maxW="600px" allowMultiple flex={1} >
          <AccordionItem>
            <h2>
              <AccordionButton
                  _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}
                borderTop="1px solid black"
                bgColor="bgImage"
              >
                <Box px={10} color="black" flex="1" textAlign="left" fontWeight="600" _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}>
                  Education
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}    bgColor="bgImage"  _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}>
              Bachelor's degree in Computer Science in 2016.
Relevant coursework: Web Development, Data Structures, Algorithms, Software Engineering.
Completed online courses and certifications in SwiftUI, Kotlin, Shopify and Wordpress.
Attended workshops and seminars on GSOC.
Continuously self-learning and staying updated with the latest industry trends and advancements.

            </AccordionPanel>
          </AccordionItem>

          <AccordionItem maxW="600px" allowMultiple flex={1}>
            <h2>
              <AccordionButton
                color="black"
                borderBottom="1px solid black"
                borderTop="1px solid black"
                bgColor="bgImage"
                _dark={{
                  bg: "gray.800",
                  color:"white"
                }}
              >
                <Box px={10} color="black" flex="1" textAlign="left" fontWeight="600"  _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}>
                  Experience
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}   bgColor="bgImage"  _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}>
              8+ years of experience as a Solution Architect, Mobile and website Developer.
Successfully delivered 55+ projects, including domains of booking, Online auctions, app, Complex business logic apps, charity apps and much more.
Collaborated with cross-functional teams to achieve project goals and meet client requirements.
Implemented best practices and industry standards to optimize project efficiency and quality.
Proven ability to problem-solve, troubleshoot, and deliver innovative solutions.
Received recognition and positive feedback from clients for exceptional performance and customer satisfaction.
Continuously sought opportunities for professional growth and skill enhancement.

            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion maxW="600px" allowMultiple flex={1}>
          <AccordionItem maxW="600px">
            <h2>
              <AccordionButton
                color="black"
                borderTop="1px solid black"
                bgColor="bgImage"
                _dark={{
                  bg: "gray.800",
                  color:"white"
                }}
              >
                <Box px={10} color="black" flex="1" textAlign="left" fontWeight="600"  _dark={{
                    bg: "gray.800",
                    color:"white",
                   
                  }}>
                  Freelancer - Mobile and Web
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} bgImage="bgImage"  _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}>
              Freelancer professional with expertise in web development and mobile app development.
Proficient in Wordpress, SwiftUI, Shopify, JavaScript, React, Angular, iOS, and Android platforms.
Strong focus on effective communication and collaboration with clients.
Embraces continuous learning and stays up-to-date with industry trends.
Passionate about delivering exceptional results ,exceeding client expectations.

            </AccordionPanel>
          </AccordionItem>

          <AccordionItem maxW="500px">
            <h2>
              <AccordionButton
                color="black"

                borderBottom="1px solid black"
                borderTop="1px solid black"
                bgColor="bgImage"
                _dark={{
                  bg: "gray.800",
                  color:"white"
                }}
              >
                <Box px={10} color="black" flex="1" textAlign="left" fontWeight="600"  _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}>
                  Internship
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel    bgColor="bgImage" pb={4}  _dark={{
                    bg: "gray.800",
                    color:"white"
                  }}>
              Completed 6 month internship at Sprinklr.
Gained practical experience and applied theoretical knowledge in iOS and Android development.
Assisted MCP, Cars24 companies in their mobile and website app development.
Collaborated with team members to achieve project objectives and meet deadlines.
Developed skills in [relevant skills or technologies] through hands-on experience.
Demonstrated strong work ethic, attention to detail, and ability to learn quickly.

            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </VStack>
  );
}

export default MyAccordion;
