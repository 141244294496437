import React from "react";
import Snavbar from "../Snav/Snavbar";
import Home from "../home/Home";
import css from "../../css/index.css";
import What from "../wid/What";
import Skills from "../skills/Skills";
import Portfolio from "../portfolioCase/Portfolio";
import Res from "../Resume/Res";
import Testi from "../testinomials/Testi";
import Price from "../pricing/Price";
// import Blog from "../blog/Blog";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";
import { useEffect, useState } from "react";
import { ChakraProvider, Flex, theme, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import './homepage.css'

const LoadingScreen = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const dotColor = useColorModeValue("#f2ede7", "#000");


  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoadingComplete(true);
    }, 2000);

    return () => clearTimeout(loadingTimer);
  }, []);

  return (
    <Flex
      align="center"
      justify="center"
      minHeight={{base:"50vh",lg:"100vh"}}
     bgColor="#f2ede7" 
     _dark={{
      bg: "gray.800",
    }}
      opacity={isLoadingComplete ? 0 : 1}
      transition="opacity 5s ease-in-out"
    >
      {!isLoadingComplete && (
        <div>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      )}
    </Flex>
  );
};

const HomePage = () => {
  const [isImageZoomed, setImageZoomed] = useState(false);
  const [isHeadingZoomed, setHeadingZoomed] = useState(false);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setImageZoomed(true);
      setHeadingZoomed(true);
    }
  }, [isLoading]);

  // const toggleColorMode = () => {
  //   setNightMode(!isNightMode);
  // };

  // const headingHeight = useBreakpointValue({ base: "20%", md: "30%" });

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }
  return (
    <div>
      <Snavbar />
      <Home />
      <What />
      <Skills />
      <Portfolio />
      <Res />
      <Testi />
      <Price />
      {/* <Blog /> */}
      <Contact />
      <Footer />
    </div>
  );
};

export default HomePage;
// const sectionRef = useRef(null);

//   const scrollToSection = () => {
//     if (sectionRef.current) {
//       sectionRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };