import {
  Box,
  Heading,
  Text,
  VStack,
  Icon,
  Input,
  Textarea,
  Button,
  FormControl,
  useColorModeValue,
  FormLabel,
  FormErrorMessage,
  Grid,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { motion } from "framer-motion";
import { FaEnvelope, FaPhone, FaMapMarker, FaUserAlt } from "react-icons/fa";

const MotionBox = motion(Box);



  function ContactSection() {
    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm();
  
    const [isLoading, setIsLoading] = useState(false);
  

    const bgColor = useColorModeValue("#f2ede7", "#000");
    const dotColor = useColorModeValue("#f2ede7", "#000");
  
  
  
    const onSubmit = async (data) => {
      const { name,  subject, message, senderEmail } = data;
      const rawResponse = await axios.post("https://port-backend-119t.onrender.com/sendEmail", {
        toEmail: 'coderoofitsolutions@gmail.com',
        subject,
        message,
        senderEmail ,
        name
      });
      if (rawResponse.status === 200) {
        setIsLoading(true); // Start the loading state
  
        try {
          // Simulate a delay of 3 seconds
          await new Promise((resolve) => setTimeout(resolve, 3000));
  
          // Show the toast alert
          const MySwal = withReactContent(Swal);
          MySwal.fire({
            icon: "success",
            title: "Mail sent successfully...",
            toast: true,
  
            showConfirmButton: false,
            timer: 3000,
          });
  
          setIsLoading(false); // Reset the loading state
        } catch (error) {
          setIsLoading(false); // Reset the loading state
          console.error(error);
        }
      }
    };

     
     

  return (
    <MotionBox
      py={8}
      bgColor={bgColor}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <VStack
        id="Contact"
        spacing={8}
        align="center"
        maxW="full"
        p={4}
        mx="auto"
      >
        <Heading
          style={{
            letterSpacing: "2px",
            fontWeight: "bolder",
            fontSize: "45px",
          }}
          textAlign="center"
        >
          CONTACT ME
        </Heading>
        <Text>
          <span
            style={{
              color: "#29A587",
              fontFamily: "Falcon Pro",
              fontSize: "16px",
              fontWeight: "bolder",
            }}
          >
            LET'S
          </span>{" "}
          <span
            style={{
              fontFamily: "Caveat",
              fontStyle: "cursive",
              fontSize: "30px",
              fontWeight: "bolder",
            }}
          >
            Talk About Ideas
          </span>
        </Text>
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          gap={{ base: 8, md: 16 }}
          w="100%"
        >
          <VStack
            spacing={9}
            ml={{ base: 0, md: 8 }}
            mt={10}
            p={8}
            align={{ base: "center", md: "flex-start" }}
          >
            <Box display="flex" alignItems="center">
              <Icon as={FaEnvelope} boxSize={10} />
              <Text
                ml={5}
                style={{
                  fontFamily: "Caveat",
                  fontStyle: "cursive",
                  fontSize: "30px",
                  fontWeight: "bolder",
                }}
              >
                mittal.30ashish@gmail.com
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Icon as={FaUserAlt} boxSize={10} />
              <Text
                ml={5}
                fontFamily="Caveat"
                fontStyle="cursive"
                fontSize="30px"
                fontWeight="bolder"
                textAlign={{ base: "center", md: "left" }}
                width="100%"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                sx={{
                  "&::after": {
                    content: `".........................."`,
                    color: dotColor,
                  },
                }}
              >
                Freelancer - Mobile and Web 
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Icon as={FaPhone} boxSize={10} />
              <Text
                ml={5}
                style={{
                  fontFamily: "Caveat",
                  fontStyle: "cursive",
                  fontSize: "30px",
                  fontWeight: "bolder",
                }}
                sx={{
                  "&::after": {
                    content: `"......"`,
                    color: dotColor,
                  },
                }}
              >
                +1 437 329 9028
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Icon as={FaMapMarker} boxSize={10} />
              <Text
                ml={5}
                style={{
                  fontFamily: "Caveat",
                  fontStyle: "cursive",
                  fontSize: "30px",
                  fontWeight: "bolder",
                }}
              >
                TD North Tower, Toronto, Canada
              </Text>
            </Box>
          </VStack>

          <VStack
            spacing={4}
            p={10}
            align="center"
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            order={{ base: 1, md: 2 }}
          >
            <FormControl isInvalid={errors.name}>
              <FormLabel htmlFor="name">YOUR FULL NAME *</FormLabel>
              <Input
                style={{
                  border: "2px solid black",
                  borderRadius: "28px",
                  backgroundColor: "white",
                  width: "100%",
                  padding: "15px",
                  placeholder: "20px",
                }}
                id="name"
                {...register("name", { required: "Name is required" })}
                placeholder="Enter your name"
                _dark={{
                  bg: "gray.800",
                  color: "black",
                }}
                _placeholder={{
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.senderEmail}>
              <FormLabel htmlFor="senderEmail">YOUR EMAIL ADDRESS *</FormLabel>
              <Input
                style={{
                  border: "2px solid black",
                  borderRadius: "28px",
                  backgroundColor: "white",
                  width: "100%",
                  padding: "15px",
                  // color: colorMode === "dark" ? "white" : "black",
                }}
                type="email"
                id="senderEmail"
                {...register("senderEmail", {  required: "Sender's Email is required" })}
                placeholder="Enter your mail"
                _dark={{
                  bg: "gray.800",
                  color: "black",
                }}
                _placeholder={{
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
                colorScheme="black"
              />
              <FormErrorMessage>
                {errors.senderEmail && errors.senderEmail.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.subject}>
              <FormLabel htmlFor="subject">YOUR SUBJECT *</FormLabel>
              <Input
                style={{
                  border: "2px solid black",
                  borderRadius: "20px",
                  backgroundColor: "white",
                  width: "100%",
                  padding: "15px",
                }}
                id="subject"
                {...register("subject", { required: "Subject is required" })}
                placeholder="subject"
                _dark={{
                  bg: "gray.800",
                  color: "black",
                }}
                _placeholder={{
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              />
              <FormErrorMessage>
                {errors.subject && errors.subject.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.message}>
              <FormLabel htmlFor="message">YOUR MESSAGE *</FormLabel>
              <Textarea
                style={{
                  border: "2px solid black",
                  borderRadius: "20px",
                  backgroundColor: "white",
                  width: "100%",
                  padding: "15px",
                }}
                id="message"
                resize="vertical"
                {...register("message", { required: "Message is required" })}
                placeholder="Enter your message"
                _dark={{
                  bg: "gray.800",
                  color: "black",
                }}
                _placeholder={{
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              />
              <FormErrorMessage>
                {errors.message && errors.message.message}
              </FormErrorMessage>
            </FormControl>
            <MotionBox
              w="100%" // Set button width to 100% on all screen sizes
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              // border="1px solid red"
            >
              <Button
                color="#000"
                fontFamily="Lato, sans-serif"
                p={5}
                fontSize="14px"
                fontWeight="bold"
                letterSpacing="1px"
                _dark={{
                  bg: "gray.700",
                  color: "white",
                  border: "2px solid white",
                }}
                transition="0.5s"
                _hover={{
                  bg: "#29A587",
                  color: "#fff",
                  animation: "ani 0.7s steps(22) forwards",
                }}
                borderRadius="30px"
                colorScheme="white"
                isLoading={isLoading}
                loadingText="Please wait..."
                boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
                border="2px solid black"
                boxSizing="border-box"
                type="submit"
              >
                SEND MESSAGE
              </Button>
            </MotionBox>
          </VStack>
        </Grid>
      </VStack>
    </MotionBox>
  );
}

export default ContactSection;
