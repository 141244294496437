import { ReactNode } from "react";
import {
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Container,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { Link } from "react-scroll";

import { ArrowForwardIcon } from "@chakra-ui/icons";

import dots from "../wid/dots.png";

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      p={12}
      maxW={{ lg: "390", md: "390", base: "380" }}
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      boxShadow={"lg"}
      rounded={"xl"}
      pos={"relative"}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={"h3"} fontSize={{ base: "xl", md: "2xl" }} textAlign="start">
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      fontFamily="Falcon Pro"
      mt={8}
      fontSize={{ base: "sm", md: "md" }}
      textAlign="start"
      maxH={{ base: "100px", md: "none" }}
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {children}
    </Text>
  );
};

export default function WithSpeechBubbles() {
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("#f2ede7", "#000");

  return (
    <Box id="Services" bgColor={bgColor}>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={{ base: "center", md: "flex-start",lg:"center" }}>
          <Heading
            style={{
              letterSpacing: "2px",
              fontWeight: "bolder",
              fontSize: "45px",
              textAlign:"center"
            }}
          >
            WHAT I DO
          </Heading>
          <Text>
            <span
              style={{
                color: "#29A587",
                fontFamily: "Falcon Pro",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              MY
            </span>{" "}
            <span
              style={{
                fontFamily: "Caveat",
                fontStyle: "cursive",
                fontSize: "30px",
                fontWeight: "bolder",
              }}
            >
              Services
            </span>
          </Text>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                  WEB DEVELOPMENT
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                  Full Stack
                </Text>
              </TestimonialText>
              <TestimonialText>
                I am a skilled web developer specializing in creating customized
                solutions using the latest technologies. With expertise in
                crafting websites, applications, and backend solutions.
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                  UI/UX
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                  Mobile and Figma Design
                </Text>
              </TestimonialText>
              <TestimonialText>
              I'm a dedicated UI/UX designer, passionate about creating
                meaningful interactions. From concept to delivery, I offer
                comprehensive design services for attractive websites & apps and
                engaging mobile apps.
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                 ANDROID
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                   Kotlin & Mobile Apps
                </Text>
              </TestimonialText>
              <TestimonialText>
              I'm a skilled Android developer, specializing in creating
                high-quality mobile applications. From design to implementation,
                I deliver seamless and user - friendly experiences. Let's bring
                your app ideas to life.
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          
       
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                   IOS
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                   Mobile Apps& Swift UI
                </Text>
              </TestimonialText>
              <TestimonialText>
              I'm an experienced iOS developer with a passion for crafting
                exceptional mobile applications. From concept to deployment,I
                specialize in creating user-centric experiences . Let's
                collaborate.
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                   CONSULTANCY
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                  Paid Consultancy
                </Text>
              </TestimonialText>
              <TestimonialText>
              I offer strategic consultancy for website development, mobile
                app and UI/UX design. I optimize digital platforms , enhance
                experiences and drive conversions. 
                <br/><br/>
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                   CUSTOM DEVELOPMENT
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                   CRM, Custom Software
                </Text>
              </TestimonialText>
              <TestimonialText>
              Specializing in crafting bespoke websites that align with your
                business goals and user needs. From intuitive interfaces to
                seamless navigation, I create tailored web solutions that
                enhance user engagement.
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          
       
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                  PHP
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                  Web Development
                </Text>
              </TestimonialText>
              <TestimonialText>
              With PHP being a highly reliable server-side scripting language,
                we provide top-quality services and expertise in developing
                customizable and tailored web solutions.
                <br /><br />
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                    WORDPRESS
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                    Web Development
                </Text>
              </TestimonialText>
              <TestimonialText>
              As a skilled WordPress web developer, I have extensive expertise
                in delivering high-quality services. With a track record of
                successfully completing over 5000+ WordPress themes, plugins,
                and websites.
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                    SHOPIFY
                </Text>
              </TestimonialHeading>
              <TestimonialText>
                <Text
                  style={{
                    fontFamily: "Falcon Pro",
                    fontWeight: "900",
                    fontSize: "19px",
                  }}
                >
                Web Development
                </Text>
              </TestimonialText>
              <TestimonialText>
              As an experienced Shopify web developer. I specialise in
                providing comprehensive support throughout the entire process of
                building an online store and Shopify theme.
                <br /><br />
              </TestimonialText>
              <Stack
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                spacing={{ base: 4, md: 6 }}
              >
                <Link
                  to="Portfolio"
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                  activeClass="active"
                >
                  <Text
                    fontWeight="700"
                    fontSize="16px"
                    cursor="pointer"
                    mt={10}
                  >
                    See Portfolio <ArrowForwardIcon color="#29A587" />
                  </Text>
                </Link>
                <Image mt={{ base: 6, md: 0 }} w="90px" src={dots} />
              </Stack>
            </TestimonialContent>
          </Testimonial>
          
       
        </Stack>
      </Container>
    </Box>
  );
}
