import { useState } from "react";
import {
    Image,
  } from "@chakra-ui/react";
const ImageWithBlur = ({ src, alt, height }) => {
    const [isImageLoaded, setImageLoaded] = useState(false);
  
    const handleImageLoad = () => {
      setImageLoaded(true);
    };
  
    const blurStyle = {
      width: '500px',
      height: '500px',
      filter: 'blur(10px)', // Adjust the blur value as needed,
    };
  
    return (
      <div style={{ position: 'relative' }}>
        {!isImageLoaded && <div style={blurStyle} />}
         <Image
              height={{ base: "200px", sm: "300px", md: "400px", lg: "500px" }}
              src={src}
              onLoad={handleImageLoad}
              style={{ opacity: isImageLoaded ? 1 : 0, transition: 'opacity 1.9s' }}
            />
      </div>
    );
  };
  
  export default ImageWithBlur