import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  Image,
  useColorMode,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";

import gradientf from "../home/hpics/gradientf.png";

import {  AiFillLinkedin} from "react-icons/ai";
import { RiBehanceFill } from "react-icons/ri";
import { FaBasketballBall } from "react-icons/fa";

import { ReactNode } from "react";
import llogo from "../nav/llogo.png";
import { Divider } from "@chakra-ui/react";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("#f2ede7", "#000");
  return (
    <chakra.button
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallWithLogoLeft() {
  const bgColor = useColorModeValue("#f2ede7", "#000");

  return (
    <Box
      borderTop="1px solid #dbdbdb"
      bgColor={bgColor}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"full"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={70}
        justify={{ base: "center", md: "space-around" }}
        align={{ base: "center", md: "center" }}
      >
        <Box display="flex" fontSize={{ base: "3xl", lg: "2xl" }}>
          <a href="https://www.linkedin.com/">
            <Text>
              {/* <AiFillLinkedin /> */}
            </Text>
          </a>
          <a href="https://dribbble.com/">
            <Text ml={6}>
              {/* <FaBasketballBall /> */}
            </Text>
          </a>
          <a href="https://www.behance.net/">
            <Text ml={6}>
              {/* <RiBehanceFill /> */}
            </Text>
          </a>
        </Box>
        <Text ml={{base:"",lg:"20"}}>
          <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
            © 2023{" "}
          </span>{" "}
          <span
            style={{ color: "#29A587", fontSize: "16px", fontWeight: "bolder" }}
          >
            
          </span>{" "}
          <span style={{ fontSize: "16px" }}>. ALL RIGHTS RESERVED</span>
        </Text>
        <Stack direction={"row"} spacing={6}>
          <Text>
            <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
              DEVELOPED BY
            </span>{" "}
            <span
              style={{
                color: "#29A587",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              ASHISH
              _MITTAL
            </span>{" "}
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}
