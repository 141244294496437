import React, { useState, useEffect} from "react";
import {
  Box,
  Button,
  Image,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Flex,
  Spinner,
} from "@chakra-ui/react";




import ImageWithBlur from "../ImageWithBlur/ImageWithBlur";
import { motion } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import LazyLoad from 'react-lazyload'


import { AiFillLinkedin } from "react-icons/ai";
import { RiBehanceFill } from "react-icons/ri";
import { FaBasketballBall } from "react-icons/fa";


const Carousel = () => {
  const items = [
    "https://codekwiks.s3.us-west-2.amazonaws.com/kk/Group+183.png",
    "https://codekwiks.s3.us-west-2.amazonaws.com/kk/Group+184.png",
    "https://codekwiks.s3.us-west-2.amazonaws.com/kk/Group+185.png",
    "https://codekwiks.s3.us-west-2.amazonaws.com/kk/Group+178.png"
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const bgColor = useColorModeValue("#f2ede7", "#000");
  const [isLoading, setIsLoading] = useState(true);
  const MotionBox = motion(Box);
  let activeInterval; // Variable to store the interval ID
  const handleGoBack = () => {
    window.history.back();
  };
  


  useEffect(() => {
    // Simulating image loading with a delay (You should replace this with actual image loading logic)
    const loadingDelay = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(loadingDelay);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when the component is mounted
    startSlideshow(); // Start the slideshow

    // Clean up the interval when the component unmounts
    return () => {
      stopSlideshow();
    };
  }, []);

  const startSlideshow = () => {
    // Start the interval for the slideshow
    activeInterval = setInterval(() => {
      handleNext();
    }, 3000);
  };

  const stopSlideshow = () => {
    // Clear the interval when the component unmounts or slideshow stops
    clearInterval(activeInterval);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };
  const buttonProperties = [
    { text: "HTML" },
    { text: "CSS" },
    { text: "JAVA SCRIPT" },
    { text: "JASON SERVER" },
    { text: "JAVA" },
    { text: "MONGO DB" },
  ];

  const Loader = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Spinner size="xl" color="teal.500" />
        {/* You can adjust the size and color of the spinner as needed */}
      </Box>
    );
  };

  const isItemActive = (index) => index === activeIndex;


  

  return (
    <Box>
      
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bg={bgColor}
      >
        <MotionBox
          bgColor={bgColor}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9 }}
        >
 
          <Heading
            style={{
              letterSpacing: "2px",
              fontWeight: "bolder",
              fontSize: "45px",
            }}
            mt="10"
            textAlign="center"
          >
            Project Details
          </Heading>
        </MotionBox>
        <Text>
          <span
            style={{
              color: "#29A587",
              fontFamily: "Falcon Pro",
              fontSize: "16px",
              fontWeight: "bolder",
            }}
          >
            LET'S
          </span>{" "}
          <span
            style={{
              fontFamily: "Caveat",
              fontStyle: "cursive",
              fontSize: "30px",
              fontWeight: "bolder",
            }}
          >
            Talk About Ideas
          </span>
        </Text>
        <Box 
        >
        <Button     borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}  fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"  _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }} mt={{base:"20px"}}   mr={{ lg: "1050px", base: "250px" }} onClick={handleGoBack} variant="outline" colorScheme="teal">
    ◁ BACK
  </Button>
        </Box>
        <Box mt={20}>
          <Heading
            fontWeight={900}
            mr={{ lg: "890px", base: "" }}
            // ml={{base:"300px",lg:"0"}}
          >
            Ishowbeauty
          </Heading>
        </Box>

        <Box maxW="100%" mt={10}>
          {/* <ImageWithBlur>
         
 <ImageWithBlur
            height={{ base: "200px", sm: "300px", md: "400px", lg: "500px" }}   src="https://codekwiks.s3.us-west-2.amazonaws.com/p12/Group+182.png"
          />
          </ImageWithBlur> */}
          <ImageWithBlur src="https://codekwiks.s3.us-west-2.amazonaws.com/kk/Group+182.png" height={{ base: "200px", sm: "300px", md: "400px", lg: "500px" }} />
        </Box>

        <Box
          mt={20}
          mx="auto"
          px={4}
          maxWidth={{ base: "90%", md: "900px" }}
          mr={{ base: "", lg: "440px" }}
        >
          <Heading fontWeight={900}>About project</Heading>
          <Text mt={6}>
            At Ishow Hair, we believe that confidence is key. That's why we're
            dedicated to helping women of all ages and backgrounds look and feel
            their best with our premium-quality human hair wigs, bundles, and
            fashion coloured wigs. We understand that hair is an essential part
            of self-expression, whether you're looking for a bold new look or a
            subtle change.
          </Text>
        </Box>
      
        <Box
  mt={20}
  mx="auto"
  px={4}
  maxWidth={{ base: "90%", md: "900px" }}
  mr={{ base: "", lg: "440px" }}
>
  <Heading fontWeight={900}>Tech Stacks</Heading>
  <Box mt={5} display="flex" flexWrap="wrap"  gap={2}>
            {/* Button 1 */}
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
              WORDPRESS
            </Button>
            {/* Button 2 */}
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
              CUSTOM PLUGINS
            </Button>
            {/* Button 3 */}
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
              CSS
            </Button>
            {/* Button 4 */}
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
              HTML
            </Button>
            {/* Button 5 */}
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
              PHP
            </Button>
            {/* Button 6 */}
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
             AJAX
            </Button>
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
             PLUGIN DEVELOPMENT
            </Button>
            <Button
              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
             OXYGEN
            </Button>
          </Box>
        </Box>
        <Box
          mt={20}
          mx="auto"
          px={4}
          maxWidth={{ base: "90%", md: "900px" }}
          mr={{ base: "150px", lg: "1040px" }}
        >
          <Heading>Project Preview</Heading>
          <a href="https://ishowbeauty.com/">
            <Button
              mt={5}
              // w={}

              fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "80%", md: "auto" }}
              flexGrow={1}
              mb={2}
            >
              LIVE PREVIEW
            </Button>
          </a>
        </Box>
        <Heading
          mt={20}
          fontWeight={900}
          sx={{
            letterSpacing: "2px",
          }}
        >
          Visuals
        </Heading>
        <Box
          mt={10}
          bg={bgColor}
          position="relative"
          width={{ lg: "1300px", base: "410px" }}
          boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
          height={{ lg: "95vh", base: "35vh" }}
          p="4"
          overflow="hidden"
        >
          {isLoading ? (
            <Loader />
          ) : (
            items.map((item, index) => (
              
              <Image
                key={index}
                src={item}
                alt={`Image ${index + 1}`}
                w="100%"
                h="100%"
                opacity={isItemActive(index) ? 1 : 0.1}
                // transform={isItemActive(index) ? "scale(1.1)" : "scale(0.9)"}
                // transition="all 0.9s ease-out"
                position={isItemActive(index) ? "relative" : "absolute"}
                zIndex={isItemActive(index) ? 1 : 0}
                left={isItemActive(index) ? 0 : "-100%"}
                right={isItemActive(index) ? 0 : "-100%"}
              />
            ))
          )}
        </Box>
        <Box mt="2">
          <Button bg="none" onClick={handlePrev} m="2">
            ◁
          </Button>
          <Button bg="none" onClick={handleNext} m="2">
            ▷
          </Button>
        </Box>

        <Box
          width="full"
          borderTop="1px solid #dbdbdb"
          bgColor={bgColor}
          color={useColorModeValue("gray.700", "gray.200")}
        >
          <Stack
            ml={{ base: "20px" }}
            maxW={{ base: "90%", md: "100%" }}
            py={4}
            direction={{ base: "column", md: "row" }}
            spacing={{ base: 4, md: 6 }}
            justify={{ base: "center", md: "space-around" }}
            align={{ base: "center", md: "center" }}
            px={{ base: 4, md: 0 }}
          >
            <Flex fontSize={{ base: "3xl", lg: "2xl" }}>
              <a href="https://www.linkedin.com/">
                <Text>
                  
                </Text>
              </a>
              <a href="https://dribbble.com/">
                <Text ml={6}>
                  
                </Text>
              </a>
              <a href="https://www.behance.net/">
                <Text ml={6}>
                  
                </Text>
              </a>
            </Flex>
            <Text ml={{ base: 0, md: 20 }}>
              <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                © 2023{" "}
              </span>
              <span
                style={{
                  color: "#29A587",
                  fontSize: "16px",
                  fontWeight: "bolder",
                }}
              >
                
              </span>{" "}
              <span style={{ fontSize: "16px" }}>. ALL RIGHTS RESERVED</span>
            </Text>
            <Stack direction={"row"} spacing={{ base: 4, md: 6 }}>
              <Text>
                <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                  DEVELOPED BY
                </span>{" "}
                <span
                  style={{
                    color: "#29A587",
                    fontSize: "16px",
                    fontWeight: "bolder",
                  }}
                >
                  ASHISH MITTAL
                </span>{" "}
              </Text>
            </Stack>
          </Stack>
        </Box>
       
      </Box>
    </Box>
  );
};

export default Carousel;
