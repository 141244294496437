import React, { useEffect, useState } from "react";
import { ChakraProvider, Flex, theme, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
// import { ColorModeSwitcher } from './ColorModeSwitcher';
// import Navbar from './components/nav/Navbar' ;
import Portfolio from "../src/components/portfolioCase/Portfolio";
// import Loading from './components/Loader/Loading'
import Seeproject from "./components/seeProject/Seeproject";
import Seeproject2 from "./components/seeProject/Seeproject2";
import Seeproject3 from "./components/seeProject/Seeproject3";
import Seeproject4 from "./components/seeProject/Seeproject4";
import Seeproject5 from "./components/seeProject/Seeproject5";
import Seeproject6 from "./components/seeProject/Seeproject6";
import Seeproject7 from "./components/seeProject/Seeproject7";
import Seeproject8 from "./components/seeProject/Seeproject8";
import Seeproject9 from "./components/seeProject/Seeproject9";
import Seeproject10 from "./components/seeProject/Seeproject10";
import Seeproject11 from "./components/seeProject/Seeproject11";
import Seeproject12 from "./components/seeProject/Seeproject12";
import Seeproject13 from "./components/seeProject/Seeproject13";
import Seeproject14 from "./components/seeProject/Seeproject14";
import Seeproject15 from "./components/seeProject/Seeproject15";
import Seeproject16 from "./components/seeProject/Seeproject16";
import Seeproject17 from "./components/seeProject/Seeproject17";
import Seeproject18 from "./components/seeProject/Seeproject18";
import Seeproject19 from "./components/seeProject/Seeproject19";
import Seeproject20 from "./components/seeProject/Seeproject20";
import Seeproject21 from "./components/seeProject/Seeproject21";
import Seeproject22 from "./components/seeProject/Seeproject22";
import Seeproject23 from "./components/seeProject/Seeproject23";
import Seeproject24 from "./components/seeProject/Seeproject24";
import Seeproject25 from "./components/seeProject/Seeproject25";
import Seeproject26 from "./components/seeProject/Seeproject26";
import Seeproject27 from "./components/seeProject/Seeproject27";
import Seeproject28 from "./components/seeProject/Seeproject28";
import Seeproject29 from "./components/seeProject/Seeproject29";
import Seeproject30 from "./components/seeProject/Seeproject30";
import Seeproject31 from "./components/seeProject/Seeproject31";
import Seeproject32 from "./components/seeProject/Seeproject32";
import Seeproject33 from "./components/seeProject/Seeproject33";
import Seeproject34 from "./components/seeProject/Seeproject34";
import Seeproject35 from "./components/seeProject/Seeproject35";
import Seeproject36 from "./components/seeProject/Seeproject36";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";


function App() {
 
  return (

    <BrowserRouter>
      <ChakraProvider theme={theme}>
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        {/* <Navbar/> */}
        {/* <Loading/> */}
        

        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/portfolio" element={<Portfolio/>} /> */}
          
          <Route path="/see-project" element={<Seeproject />} />
          <Route path="/see-project2" element={<Seeproject2 />} />
          <Route path="/see-project3" element={<Seeproject3 />} />
          <Route path="/see-project4" element={<Seeproject4 />} />
          <Route path="/see-project5" element={<Seeproject5 />} />
          <Route path="/see-project6" element={<Seeproject6 />} />
          <Route path="/see-project7" element={<Seeproject7 />} />
          <Route path="/see-project8" element={<Seeproject8 />} />
          <Route path="/see-project9" element={<Seeproject9 />} />
          <Route path="/see-project10" element={<Seeproject10 />} />
          <Route path="/see-project11" element={<Seeproject11 />} />
          <Route path="/see-project12" element={<Seeproject12 />} />
          <Route path="/see-project13" element={<Seeproject13 />} /> 
          <Route path="/see-project14" element={<Seeproject14 />} />
          <Route path="/see-project15" element={<Seeproject15 />} />
          <Route path="/see-project16" element={<Seeproject16 />} />
          <Route path="/see-project17" element={<Seeproject17 />} />
          <Route path="/see-project18" element={<Seeproject18 />} />
          <Route path="/see-project19" element={<Seeproject19 />} />
          <Route path="/see-project20" element={<Seeproject20 />} />
          <Route path="/see-project21" element={<Seeproject21 />} />
          <Route path="/see-project22" element={<Seeproject22 />} />
          <Route path="/see-project23" element={<Seeproject23 />} />
          <Route path="/see-project24" element={<Seeproject24 />} />
          <Route path="/see-project25" element={<Seeproject25 />} />
          <Route path="/see-project26" element={<Seeproject26 />} />
          <Route path="/see-project27" element={<Seeproject27 />} />
          <Route path="/see-project28" element={<Seeproject28 />} />
          <Route path="/see-project29" element={<Seeproject29 />} />
          <Route path="/see-project30" element={<Seeproject30 />} />
          <Route path="/see-project31" element={<Seeproject31 />} />
          <Route path="/see-project32" element={<Seeproject32 />} />
          <Route path="/see-project33" element={<Seeproject33 />} />
          <Route path="/see-project34" element={<Seeproject34 />} />
          <Route path="/see-project35" element={<Seeproject35 />} />
          <Route path="/see-project36" element={<Seeproject36 />} />
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
