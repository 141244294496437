import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";

import profile2 from "../home/hpics/profile2.png";
import "./home.css";

import { AiFillLinkedin } from "react-icons/ai";
import { RiBehanceFill } from "react-icons/ri";
import { FaBasketballBall } from "react-icons/fa";

import g from "./hpics/g.png";
import { useRef } from "react";

const LoadingScreen = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const dotColor = useColorModeValue("#f2ede7", "#000");

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoadingComplete(true);
    }, 2000);

    return () => clearTimeout(loadingTimer);
  }, []);

  return (
    <Flex
      align="center"
      justify="center"
      minHeight={{ base: "50vh", lg: "100vh" }}
      bgColor="#f2ede7"
      _dark={{
        bg: "gray.800",
      }}
      opacity={isLoadingComplete ? 0 : 1}
      transition="opacity 5s ease-in-out"
    >
      {!isLoadingComplete && (
        <div>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      )}
    </Flex>
  );
};

export default function SplitScreen() {
  const [isImageZoomed, setImageZoomed] = useState(false);
  const [isHeadingZoomed, setHeadingZoomed] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isNightMode, setNightMode] = useState(false);
  const bgColor = useColorModeValue("#f2ede7", "#000");



  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setImageZoomed(true);
      setHeadingZoomed(true);
    }
  }, [isLoading]);


  const headingHeight = useBreakpointValue({ base: "20%", md: "30%" });



  return (
    <Stack
      mt="80px"
      id="Home"
      bgColor={bgColor}
      className={isNightMode ? "night-mode" : "day-mode"} // Add day-mode and night-mode classes
      minH={"full"}
      direction={{ base: "column", md: "row" }}
      spacing={0}
      overflow={"hidden"}
    >
      <Box
        w={500}
        // h={500}
       
        bg="green.500"
        borderRadius="full"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        zIndex={-1}
      />

      <Flex
        p={8}
        flex={1}
        mb={20}
        align={"center"}
        justify={"center"}
        transform={isHeadingZoomed ? "translateY(0)" : "translateY(-100px)"}
        opacity={isHeadingZoomed ? 1 : 0}
        transition="transform 1s ease-in-out, opacity 1s ease-in-out"
      >
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading
            fontWeight={40}
            mt="60px"
            fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
            transform={isHeadingZoomed ? "scale(1.1)" : "none"}
            transition="transform 1s ease-in-out"
          >
            <Text
              style={{ fontWeight: "600" }}
              fontSize={16}
              
              as="span"
              position="relative"
              _after={{
                content: "''",
                width: "full",
                height: headingHeight,
                position: "absolute",
                bottom: 1,
                left: 0,
                zIndex: -1,
                transition: "height 1s ease-in-out",
              }}
            >
              <span>HELLO</span>,{" "}
              <span style={{ color: "#29A587", fontFamily: "Falcon Pro" }}>
                MY NAME IS
              </span>
            </Text>
            <br />
            <Text
              textShadow="-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"
              fontWeight={800}
              fontFamily="KG Red Hands"
              fontSize={75}
            >
              <Text as="span" color="#29A587">
                ASHISH
              </Text>{" "}
              <Text as="span" color="white">
                MITTAL
              </Text>
            </Text>

            <Text as={"span"}>
              <span
                style={{
                  fontWeight: "600",
                  fontFamily: "Falcon Pro",
                  fontSize: "18px",
                  letterSpacing: "10",
                }}
              >
                I AM
              </span>
              ,{" "}
              <span
                style={{
                  fontFamily: "Caveat",
                  fontStyle: "cursive",
                  fontWeight: "1300",
                  fontSize: "33px",
                }}
              >
                Mobile and Web App Developer
              </span>
            
            </Text>

            <Text
              style={{ fontWeight: "370", marginTop: "30px", fontSize: "16px" }}
              fontSize={{ base: "md", lg: "lg" }}
            >
              I'm an accomplished software developer hailing from Toronto,
              Canada, with a rich background spanning 8+ years. My passion lies
              in crafting cutting-edge web applications, mobile apps, and
              architecting innovative solutions for businesses. I'm eager to
              delve into intriguing discussions about our unique projects. Let's
              connect and bring our visions to life with exceptional results.
            </Text>
            <Box
              display="flex"
              mt={10}
              fontSize={{ base: "2xl", lg: "30px" }}
              ml={{ base: "130px", lg: "0" }}
            >
              <a href="https://www.linkedin.com/">
                <Text>
                  {/* <AiFillLinkedin /> */}
                </Text>
              </a>
              <a href="https://dribbble.com/">
                <Text ml={3}>
                  {/* <FaBasketballBall /> */}
                </Text>
              </a>
              <a href="https://www.behance.net/">
                <Text ml={3}>
                  {/* <RiBehanceFill /> */}
                </Text>
              </a>
            </Box>
            {/* <Text  fontSize={{ base: 'md', lg: '2xl' }} display="flex"  ><FaBasketballBall/><AiOutlineTwitter/><RiBehanceFill/></Text> */}
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <Box display="inline-block" position="relative" mt={5}>
                <a href="https://drive.google.com/uc?export=download&id=1I--h2rLrQDWPqnSFpMcH99LMjyt5Yiqv">
                  <Button
                    id="downloadcv"
                    w="100%"
                    fontFamily="Lato, sans-serif"
                    // bgColor=""
                    // _hover={{bgColor="#29A587"}}
                    // transition="transform .9s"
                    p={7}
                    fontSize="14px"
                    fontWeight="bold"
                    letterSpacing="1px"
                    _dark={{
                      border: "2px solid white",
                    }}
                    transition="0.5s"
                    _hover={{
                      bg: "#29A587",
                      color: "#fff",
                      animation: "ani 0.7s steps(22) forwards",
                    }}
                    borderRadius="30px" // Set border radius to 0
                    // color="black"
                    bg=""
                    boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
                    border="2px solid black"
                    boxSizing="border-box"
                  >
                    DOWNLOAD CV
                  </Button>
                </a>
                <Box
                  position="absolute"
                  top="50%"
                  right="-46px"
                  transform="translateY(-50%)"
                  height="2px"
                  width="48px"
                  backgroundColor="black"
                  _dark={{
                    bg: "white",
                  }}
                  boxSizing="border-box"
                />
              </Box>

              <Text
                ml={{ lg: "60px", base: "135px" }}
                fontFamily="Lato, sans-serif"
                fontSize="16px"
                fontWeight="bold"
                mt="44px"
                // width={{ base: "40%", md: "50%", lg:"20%" }}
              >
                MY SKILLS
              </Text>

              {/* <Button
            mt="5px"
            ml={10}
              transition="transform .9s"
              _hover={{ transform: 'scale(1.2)' }}
              boxShadow={'10px 5px 5px grey'}
              rounded={'full'}
              back
              colorScheme='whiteAlpha'
              color="black"
            >
              Skills
            </Button> */}
            </Stack>
          </Heading>
        </Stack>
      </Flex>
      <Flex
        flex={1}
        style={{ cursor: "pointer", overflow: "hidden" }}
        transform={isImageZoomed ? "translateY(0)" : "translateY(100px)"}
        opacity={isImageZoomed ? 1 : 0}
        transition="transform 1s ease-in-out, opacity 1s ease-in-out"
      >
        <Image
          style={{
            // backgroundColor: '#29A587',
            // backgroundImage:""
            // marginTop: '10px',
            backgroundImage: `url(${g})`, // Set the background image using the backgroundImage property
            backgroundPosition: "center 10%", // Move the background image slightly down
            borderRadius: "50%",
          }}
          bgImage={g}
          alt={"Login Image"}
          objectFit={isImageZoomed ? "contain" : "cover"}
          src={profile2}
          transition="transform 1s ease-in-out"
          transform={isImageZoomed ? "scale(0.9)" : "none"}
        />
      </Flex>
    </Stack>
  );
}
