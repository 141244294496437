import { ReactNode, useEffect } from "react";
import { IconButton } from "@chakra-ui/react";
import { HiMenuAlt4, HiOutlineSun, HiOutlineMoon } from "react-icons/hi";
import { AiOutlineTwitter } from "react-icons/ai";
import { RiBehanceFill } from "react-icons/ri";
import { FaBasketballBall } from "react-icons/fa";
// import {BsMoon} from 'react-icons/bs';
// import {MdSunny} from 'react-icons/md'
import {
  Box,
  Flex,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  useColorMode,
  Center,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import lrg from "../Snav/lrg.png";

const CustomMenuItem = ({ children }: { children: ReactNode }) => (
  <MenuItem
    style={{
      position: "relative",
      paddingLeft: "164px",
      fontWeight: "bold",
      marginBottom: "10px",
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        marginLeft: "23px",
        bottom: 0,
        width: "2px",
        backgroundColor: "#000",
      }}
    />
    {children}
  </MenuItem>
);



const handleScroll = (id: string, onClose: () => void) => {
  const element = document.getElementById(id);
  if (element) {
    const offset = 60;
    const elementPosition = element.offsetTop - offset;
    window.scrollTo({
      top: elementPosition,
      behavior: "smooth",
    });
  }
  onClose();
};

export default function Nav() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.getElementById("navbar");
      if (nav && window.scrollY > 0) {
        nav.classList.add("scrolled");
      } else if (nav) {
        nav.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        position="fixed"
        zIndex={9999}
        width="100%"
        top="0"
        _dark={{
          bg: "gray.800",
        }}
        bg="linear-gradient(to right top, #f0ece4, #f1ece5, #f1ece5, #f2ede6, #f2ede7, #f2ede7, #f2eee8, #f2eee8, #f2eee8, #f3efe8, #f3efe8, #f3efe8)"
        px={5}
        h={20}
        // overflow="hidden"
        // width="100%"
      >
        <Flex h="100%" alignItems={"center"} justifyContent={"space-between"}  >
          <Box width={{ base: "40%", md: "50%", lg:"20%" }} height="auto"  >
            <Image  width="32%" height="auto" src=""   />
          </Box>

          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              <Button bg="" onClick={toggleColorMode}>
                {colorMode === "light" ? (
                  <HiOutlineMoon size={30} />
                ) : (
                  <HiOutlineSun size={30} />
                )}
              </Button>

              <Menu>
                <MenuButton
                  border=""
                  as={IconButton}
                  aria-label="Options"
                  icon={<HiMenuAlt4 size={30} />}
                  variant="outline"
                  onClick={isOpen ? onClose : onOpen}
                />
                <MenuList
                  style={{
                    width: "400px",
                    height: "690px",
                    fontSize: "19px",
                    fontWeight: "bolder",
                    spacing: "50",
                  }}
                >
                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Home", onClose)}>
                      Home
                    </div>
                  </CustomMenuItem>

                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Services", onClose)}>
                      Services
                    </div>
                  </CustomMenuItem>

                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Skills", onClose)}>
                      Skills
                    </div>
                  </CustomMenuItem>
                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Portfolio", onClose)}>
                      Portfolio
                    </div>
                  </CustomMenuItem>
                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Resume", onClose)}>
                      Resume
                    </div>
                  </CustomMenuItem>
                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Testimonials", onClose)}>
                      Testimonials
                    </div>
                  </CustomMenuItem>
                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Pricing", onClose)}>
                      Pricing
                    </div>
                  </CustomMenuItem>
                  {/* <CustomMenuItem>
                    <div onClick={() => handleScroll("Blog", onClose)}>
                      Blog
                    </div>
                  </CustomMenuItem> */}
                  <CustomMenuItem>
                    <div onClick={() => handleScroll("Contact", onClose)}>
                      Contact
                    </div>
                  </CustomMenuItem>
              
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
