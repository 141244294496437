import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  Button,
  Grid,
  Image,
  Text,
  Stack,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import dots from "../wid/dots.png";

import { Link } from "react-router-dom";

const Portfolio = () => {
  const bgColor = useColorModeValue("#f2ede7", "#000");
  const [selectedCategory, setSelectedCategory] = useState("branding");
  const [showMore, setShowMore] = useState(false);
  const [shuffleKey, setShuffleKey] = useState(0);

  

  const IMAGE =
    "https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80";

    const toggleShowMore = () => {
      setShowMore((prevState) => !prevState);
    };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setShuffleKey((prevKey) => prevKey + 1);
  };

  const handleViewMore = () => {
    if (selectedCategory === "branding") {
      setShowMore(true);
    }
  };
  

  useEffect(() => {
    setShowMore(false);
  }, [selectedCategory]);

  

  return (
    <Box id="Portfolio" pt={20} bgColor={bgColor}>
      <Container h="100%" maxW="container.xl">
        <Stack spacing={0} align={"center"}>
          <Heading
            style={{
              // fontFamily: 'Falcon Pro',
              letterSpacing: "2px",
              fontWeight: "bolder",
              fontSize: "45px",
            }}
          >
            PORTFOLIO
          </Heading>
          <Text>
            <span
              style={{
                color: "#29A587",
                fontFamily: "Falcon Pro",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              MY
            </span>{" "}
            <span
              style={{
                // color: "#000",
                fontFamily: "Caveat",
                fontStyle: "cursive",
                fontSize: "30px",
                fontWeight: "bolder",
              }}
            >
              Cases
            </span>
          </Text>
        </Stack>
        <Flex
          mt={10}
          h="100%"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {/* Category Buttons */}

          <Flex mb={4} flexWrap="wrap">
          {/* <Link to="/">
            <Button
            bg="none"
            fontSize="14px"
              fontWeight="700"
              color={selectedCategory === "home" ? "green" : "bgColor"}
              _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }}
              borderRadius="30px"
          
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "80%", md: "auto" }}
              flexGrow={1}
              mr={2}
              mb={2}
             
            >
              Back to Homepage
            </Button>
            </Link> */}
            <Button
              fontSize="14px"
              fontWeight="700"
              color={selectedCategory === "branding" ? "green" : "bgColor"}
              bg="none"
              mr={2}
              mb={2}
              onClick={() => handleCategoryClick("branding")}
            >
              ALL
            </Button>
            <Button
              fontWeight="700"
              // bg="black"
              // color={selectedCategory === "ui-ux" ? "green" : "#000"}
              color={selectedCategory === "ui-ux" ? "green" : "bgColor"}
              fontSize="14px"
              bg="none"
              // colorScheme={selectedCategory === 'branding' ? 'blue' : 'gray'}
              mr={2}
              mb={2}
              onClick={() => handleCategoryClick("ui-ux")}
            >
              MOBILE APP DEVELOPMENT
            </Button>
            <Button
              fontWeight="700"
              bg="none"
              // color={selectedCategory === "web" ? "green" : "#000"}
              color={selectedCategory === "web" ? "green" : "bgColor"}
              fontSize="14px"
              // colorScheme={selectedCategory === 'branding' ? 'blue' : 'gray'}
              mr={2}
              mb={2}
              onClick={() => handleCategoryClick("web")}
            >
              WEB DEVELOPMENT
            </Button>
            <Button
              fontWeight="700"
              // bg="black"
              //  color="#000"
              fontSize="14px"
              // color={selectedCategory === "mobile" ? "green" : "#000"}
              color={selectedCategory === "mobile" ? "green" : "bgColor"}
              bg="none"
              // colorScheme={selectedCategory === 'branding' ? 'blue' : 'gray'}
              mr={2}
              mb={2}
              onClick={() => handleCategoryClick("mobile")}
            >
              UI UX DESIGN
            </Button>
          
          </Flex>

          {/* Portfolio Boxes */}
          <Box justifyContent={"space-evenly"} mt={10}>
            <Grid
              key={shuffleKey}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              }}
              gap={9}
              maxW="1300px"
              mx="auto"
            >
              {selectedCategory === "branding" && (
                <>
                  <Box
                    // bgColor="white"
                    role={"group"}
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/C70CBKm/7.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        fontWeight="700"
                        textTransform={"uppercase"}
                      >
                        Web Development
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Ishowbeauty
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          At Ishow Hair, we believe that confidence is key.
                          That's why we're dedicated to helping women of all
                          ages and backgrounds look and feel their best with our
                          quality hair.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project6">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/0ByJMLN/10.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        web development
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Smugmug
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          With SmugMug, you can keep every pixel safe, share
                          securely with whoever you want, or use our sales
                          platform to make money selling prints and downloads.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project7">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/5WtXD6q/4.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        web development
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Mibmovers
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          MIB Moving is a family owned company that takes great
                          pride.Whether you are planning to move locally, across
                          Canada or the US; we will carefully and professionally
                          handle your needs.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project8">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    role={"group"}
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                    id="purpity"
                  
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/qM7Bm5t/a-3.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        fontWeight="700"
                        textTransform={"uppercase"}
                      >
                        mobile app development
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Purpity
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          An internal management app, designed to streamline
                          project, event and survey scheduling for employees.
                          With intuitive features and real-time updates.
                        </Text>
                      </Stack>
                      <Heading
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project9">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/XL3hmPN/Codekwiks-2-2.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        mobile app development
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        PhilaFun
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Discover a world of giving with this innovative
                          charity app. Charities from diverse causes can
                          register, creating impactful campaigns for users to
                          donate and support
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project10">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/74yPX0j/3-694.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        mobile app development
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Giftminder
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          For all of your gift-giving occasions, GIFT MINDERS is
                          a new online gift-shopping resource that helps you
                          find specialty gift shops, customized presents,
                          handmade gifts.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project11">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/X7FFZKg/Group-356.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        fontWeight={700}
                        textTransform={"uppercase"}
                      >
                        UI UX design
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Sylva
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          SYLVA is an integrated learning and assessment
                          platform that automates all tedious processes in
                          education so that you and your students can focus on.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project12">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/BTfpxPq/Group-353.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Fluff Interior Design
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Our client wanted to have a platform where they can
                          showcase their previous projects with great graphics
                          and design and have an interactive space for their
                          customers
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project13">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/B4030Vv/9.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Greens Express
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          CBD Products contain the non-psychoactive compounds in
                          cannabis that have lots of healing properties and
                          benefits without having to get "high."
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project14">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  {showMore && (
                    <>
                      <Box
                        // bgColor="white"
                        role={"group"}
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/dBQk4Sm/Slide-16-9-3-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight="700"
                            textTransform={"uppercase"}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            ALOALOU
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Get closer to your favorite social media
                              celebrities with this groundbreaking app. Send
                              personalized requests for custom videos, whether
                              it's a shoutout.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        role={"group"}
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/b5JyBb3/115.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight="700"
                            textTransform={"uppercase"}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            JOZEKO
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              A social media platform that redefines visual
                              storytelling. Capture and share your life's
                              highlights through stunning connect with friends,
                              and explore a world of inspiration.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project36">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        role={"group"}
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/VDTNM7h/3-695.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight="700"
                            textTransform={"uppercase"}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            All The Virtue
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              This app employs a normative method of ethical
                              mediation, delivering virtuous resolutions to
                              passionate couples seeking to resolve their
                              conflicts.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project2">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        role={"group"}
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/yFMYv8H/Group-250.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight="700"
                            textTransform={"uppercase"}
                          >
                            Web Development
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Scientia
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Optimise timetabling, resource booking.Flexible
                              timetabling and scheduling for any type of course
                              or program, on an individual, group, class and
                              course level.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project3">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        role={"group"}
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/3vzvktB/Group-396.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight="700"
                            textTransform={"uppercase"}
                          >
                            Web Development
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            WAYSLIM & SkIN
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              At WaySlim & Skin, we understand that our patients
                              are trusting us to help them look their best.
                              That's why we take pride in the work we do.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project4">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        role={"group"}
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/WP6r8Yh/Group-343.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight="700"
                            textTransform={"uppercase"}
                          >
                            Web Development
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Innerwest Proprty
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Established in 2020, we have a proud record of
                              consistent performance for our clients. Managing
                              massive portfolio house and apartments.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project5">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/PNVYbxB/Slide-16-9-1-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            Mobile App Development
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            CARS 24
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              CARS24 is your single stop for buying or selling
                              used cars. We've brought together cutting-edge
                              technology with world and country-wide partners.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project15">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/DppyqR9/Group-407-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Xsports
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              To make XSPORTS the foremost choice of buyers, it
                              was necessary that we come up with a reliable,
                              transparent, secure, and user-friendly trading
                              platform.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project16">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/wWrX5sK/Screenshot-1687611728.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            My City People
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              An app that bridges the gap between like-minded
                              individuals. Connect with diverse communities,
                              create engaging chat forums, and explore a vast
                              array of groups.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project17">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/nP2VstZ/Group-385.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Carrot Corner
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Pete Clarke has been creating successful poker
                              players for over a decade. His courses are
                              renowned for their academic rigour and
                              professional style.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project18">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/ZY8GM8f/Group-377.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            JMW
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              JMW promises to deliver extraordinary results, to
                              every client, every time. They facilitate
                              breakthrough performance by connecting the
                              company's purpose to the hearts and minds.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project19">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/Z2Cc6Qt/1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Pocarr
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              The poker world is a lot different than it was
                              several years ago and it’s a lot more competitive.
                              Pocarr provides the best coaching to help each
                              player reach their full poker.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project20">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/dMQ9ymb/Mac-Book-25.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Codekwiks
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Codekwiks is a full-service technology consulting,
                              design & development firm with an office in Los
                              Angeles. It was started as a company to set
                              industry standards.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project21">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/r2dVFPJ/Mac-Book-29.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Codesquarelabs
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Our team consists of software experts from the
                              best schools (Stanford, UC Berkeley, University of
                              Michigan, UCLA, USC and etc) around the nation.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project22">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/3YJMmxD/Mac-Book-141-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Codingearth
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Get all your assignments done with our
                              expertise.We understand how important it is to
                              stay on time and in budget.Provides quality and
                              confidence.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project23">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/2F9c0wH/Group-350.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            MRCS
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Their range of professional, commercial cleaning
                              services will give your place of business a
                              highly-professional and immaculately clean look
                              and feel.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project24">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/yR22SjS/Group-371.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            A.J.A & MORE
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              A.J.A & MORE is a family-operated business in
                              Southern California with a commitment to offering
                              you solutions and supplies that help you organize,
                              simplify, declutter.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project25">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/D9qgFDv/Group-343.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Magnum Air
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Magnum Air, Inc. is a premier industry leader in
                              the Houston area providing excellence in
                              Commercial, Mechanical Service, and Installation.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project26">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/2K67TPw/3.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Suntree
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Our clients wanted a platform that can be accessed
                              by their members easily. A platform where the
                              members can book any eventwithout having to visit
                              the club again.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project27">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/NZ0mh8w/5.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Camping World
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Since 1966, Camping World has proudly offered
                              specialized products and accessories, expert
                              advice, and professional service to recreational
                              vehicle owners and campers.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project28">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/72Zt552/6.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            eBRIGHT ACADEMY
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              eBRIGHT Academy offers a comprehensive mathematics
                              curriculum that develops the essential skills of
                              your kids. Every child is special and has the
                              potential to achieve.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project29">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/xsWT3QF/Group-235.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Flypay
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Flypay is a new online payment service that allows
                              you to check out faster when shopping online and
                              collect Flybuys points along the way.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project30">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/09Sb0dF/Screenshot-2023-07-12-150930.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            ONEASSIST
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              We provide Assistance and Protection for the
                              things you are most dependent on, in your everyday
                              lives.Celebrating 12 Years of Protecting everyday
                              things that matter you.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project31">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/VT5R1t0/Slide-16-9-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Oxigen Wallet
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              The Oxigen Wallet is India’s first non-bank
                              wallet, that has tied up with National Payment
                              Corporation of India, allowing instant money
                              transfer from the Wallet to 60+ banks.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project32">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/vDJH07Q/2.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Florida Spine and Sports Specialist
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Florida Spine and Sports Specialists is a
                              one-of-a-kind practice in the south Florida
                              region. With a multi-disciplinary approach to
                              spinal care.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project33">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/qxrnX7r/Slide-16-9-3.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            EXSPO
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              It is the ultimate platform for connecting sports
                              enthusiasts with experienced coaches in their
                              local area. Discover a wide range of sports
                              disciplines.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project34">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/rHFbCYn/Group-376.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            DNKL.DSGN
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              A Marketing Company which makes companies visible
                              and bring them forward with the help of different
                              marketing measures. They help in generating more
                              sales.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project35">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                    </>
                  )}
                </>
              )}

              {selectedCategory === "ui-ux" && (
                <>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/dBQk4Sm/Slide-16-9-3-1.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        MOBILE APP DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        ALOALOU
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Get closer to your favorite social media celebrities
                          with this groundbreaking app. Send personalized
                          requests for custom videos, whether it's a shoutout.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/b5JyBb3/115.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        MOBILE APP DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        JOZEKO
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          A social media platform that redefines visual
                          storytelling. Capture and share your life's highlights
                          through stunning connect with friends, and explore a
                          world of inspiration.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project36">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/VDTNM7h/3-695.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        MOBILE APP DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        All The Virtue
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          This app employs a normative method of ethical
                          mediation, delivering virtuous resolutions to
                          passionate couples seeking to resolve their conflicts.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project2">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/PNVYbxB/Slide-16-9-1-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            Mobile App Development
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            CARS 24
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              CARS24 is your single stop for buying or selling
                              used cars. We've brought together cutting-edge
                              technology with world and country-wide partners.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project15">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/DppyqR9/Group-407-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Xsports
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              To make XSPORTS the foremost choice of buyers, it
                              was necessary that we come up with a reliable,
                              transparent, secure, and user-friendly trading
                              platform.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project16">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/wWrX5sK/Screenshot-1687611728.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            My City People
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              An app that bridges the gap between like-minded
                              individuals. Connect with diverse communities,
                              create engaging chat forums, and explore a vast
                              array of groups.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project17">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/xsWT3QF/Group-235.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Flypay
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Flypay is a new online payment service that allows
                              you to check out faster when shopping online and
                              collect Flybuys points along the way.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project30">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/09Sb0dF/Screenshot-2023-07-12-150930.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            ONEASSIST
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              We provide Assistance and Protection for the
                              things you are most dependent on, in your everyday
                              lives.Celebrating 12 Years of Protecting everyday
                              things that matter you.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project31">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/VT5R1t0/Slide-16-9-1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Oxigen Wallet
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              The Oxigen Wallet is India’s first non-bank
                              wallet, that has tied up with National Payment
                              Corporation of India, allowing instant money
                              transfer from the Wallet to 60+ banks.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project32">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                  {showMore && (
        <>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/qM7Bm5t/a-3.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        MOBILE APP DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Purpity
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          An internal management app, designed to streamline
                          project, event and survey scheduling for employees.
                          With intuitive features and real-time updates.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project9">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/XL3hmPN/Codekwiks-2-2.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        MOBILE APP DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        PhilaFun
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Discover a world of giving with this innovative
                          charity app. Charities from diverse causes can
                          register, creating impactful campaigns for users to
                          donate and support.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project10">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/74yPX0j/3-694.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        MOBILE APP DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Giftminder
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          For all of your gift-giving occasions, GIFT MINDERS is
                          a new online gift-shopping resource that helps you
                          find specialty gift shops, customized presents,
                          handmade gifts.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project11">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/qxrnX7r/Slide-16-9-3.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            MOBILE APP DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            EXSPO
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              It is the ultimate platform for connecting sports
                              enthusiasts with experienced coaches in their
                              local area. Discover a wide range of sports
                              disciplines.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project34">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                  
                  </>
                  
      )}
       
                </>
                
              )}
             

              {selectedCategory === "web" && (
                <>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/C70CBKm/7.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        WEB DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Ishowbeauty
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          At Ishow Hair, we believe that confidence is key.
                          That's why we're dedicated to helping women of all
                          ages and backgrounds look and feel their best with our
                          quality hair.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project6">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/nP2VstZ/Group-385.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        Web DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Carrot Corner
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Pete Clarke has been creating successful poker players
                          for over a decade. His courses are renowned for their
                          academic rigour and professional style.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project18">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/5WtXD6q/4.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        WEB DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Mibmovers
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          MIB Moving is a family owned company that takes great
                          pride.Whether you are planning to move locally, across
                          Canada or the US; we will carefully and professionally
                          handle your needs.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project8">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/ZY8GM8f/Group-377.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            JMW
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              JMW promises to deliver extraordinary results, to
                              every client, every time. They facilitate
                              breakthrough performance by connecting the
                              company's purpose to the hearts and minds.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project19">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/Z2Cc6Qt/1.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Pocarr
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              The poker world is a lot different than it was
                              several years ago and it’s a lot more competitive.
                              Pocarr provides the best coaching to help each
                              player reach their full poker.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                mt={10}
                              >
                                <Link to="/see-project20">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/WP6r8Yh/Group-343.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        WEB DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Innerwest Property
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Established in 2020, we have a proud record of
                          consistent performance for our clients. Managing
                          massive portfolio house and apartments.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project5">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>

                  <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/2F9c0wH/Group-350.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            MRCS
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Their range of professional, commercial cleaning
                              services will give your place of business a
                              highly-professional and immaculately clean look
                              and feel.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project24">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/yR22SjS/Group-371.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            A.J.A & MORE
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              A.J.A & MORE is a family-operated business in
                              Southern California with a commitment to offering
                              you solutions and supplies that help you organize,
                              simplify, declutter.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project25">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/D9qgFDv/Group-343.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            WEB DEVELOPMENT
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Magnum Air
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Magnum Air, Inc. is a premier industry leader in
                              the Houston area providing excellence in
                              Commercial, Mechanical Service, and Installation.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project26">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                   
                  {showMore && (
        <>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/WP6r8Yh/Group-343.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        WEB DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Innerwest Property
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Established in 2020, we have a proud record of
                          consistent performance for our clients. Managing
                          massive portfolio house and apartments.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project5">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/yFMYv8H/Group-250.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        WEB DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Scientia
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Optimise timetabling, resource booking.Flexible
                          timetabling and scheduling for any type of course or
                          program, on an individual, group, class and course
                          level.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project3">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/3vzvktB/Group-396.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        WEB DEVELOPMENT
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        WAYSLIM & SkIN
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          At WaySlim & Skin, we understand that our patients are
                          trusting us to help them look their best. That's why
                          we take pride in the work we do.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project4">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/0ByJMLN/10.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        web development
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Smugmug
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          With SmugMug, you can keep every pixel safe, share
                          securely with whoever you want, or use our sales
                          platform to make money selling prints and downloads.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{ fontWeight: "700", fontSize: "16px" }}
                            mt={10}
                          >
                            <Link to="/see-project7">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  </>
                  
                  )}
                    
                  
                </>
                
              )}

              {selectedCategory === "mobile" && (
                <>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/dMQ9ymb/Mac-Book-25.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Codekwiks
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Codekwiks is a full-service technology consulting,
                          design & development firm with an office in Los
                          Angeles. It was started as a company to set industry
                          standards.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project21">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/r2dVFPJ/Mac-Book-29.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Codesquarelabs
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Our team consists of software experts from the best
                          schools (Stanford, UC Berkeley, University of
                          Michigan, UCLA, USC and etc) around the nation,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project22">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/3YJMmxD/Mac-Book-141-1.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Codingearth
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Get all your assignments done with our expertise.We
                          understand how important it is to stay on time and in
                          budget.Provides quality and confidence.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project23">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/2K67TPw/3.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Suntree
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Our clients wanted a platform that can be accessed
                              by their members easily. A platform where the
                              members can book any eventwithout having to visit
                              the club again.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project27">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/NZ0mh8w/5.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            Camping World
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              Since 1966, Camping World has proudly offered
                              specialized products and accessories, expert
                              advice, and professional service to recreational
                              vehicle owners and campers.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project28">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>

                      <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/72Zt552/6.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            eBRIGHT ACADEMY
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              eBRIGHT Academy offers a comprehensive mathematics
                              curriculum that develops the essential skills of
                              your kids. Every child is special and has the
                              potential to achieve.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project29">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                  {showMore && (
        <>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/X7FFZKg/Group-356.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Sylva
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          SYLVA is an integrated learning and assessment
                          platform that automates all tedious processes in
                          education so that you and your students can focus on.
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project12">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/BTfpxPq/Group-353.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Fluff Interior Design
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Our client wanted to have a platform where they can
                          showcase their previous projects with great graphics
                          and design and have an interactive space for their
                          customers,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project13">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src="https://i.ibb.co/B4030Vv/9.png"
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        UI UX DESIGN
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Greens Express
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          CBD Products contain the non-psychoactive compounds in
                          cannabis that have lots of healing properties and
                          benefits without having to get "high."
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project14">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                        // bgColor="white"
                        bg="white"
                        _dark={{
                          bg: "gray.800",
                        }}
                        mt={10}
                        role={"group"}
                        p={6}
                        maxW={"360px"}
                        w={"full"}
                        // bg={useColorModeValue('white', 'gray.800')}
                        boxShadow={"2xl"}
                        rounded={"lg"}
                        pos={"relative"}
                        zIndex={1}
                      >
                        <Box
                          _hover={{ transform: "scale(1.1)" }}
                          transition="transform 0.3s ease"
                          rounded={"lg"}
                          // mt={-12}
                          pos={"relative"}
                          height={"230px"}
                          _after={{
                            transition: "all .3s ease",
                            content: '""',
                            w: "full",
                            h: "full",
                            // pos: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundImage: `url(${IMAGE})`,
                            filter: "blur(15px)",
                            zIndex: -1,
                          }}
                          _groupHover={{
                            _after: {
                              filter: "blur(20px)",
                            },
                          }}
                        >
                          <Image
                            rounded={"lg"}
                            height={230}
                            width={309}
                            objectFit={"cover"}
                            src="https://i.ibb.co/rHFbCYn/Group-376.png"
                          />
                        </Box>
                        <Stack pt={10}>
                          <Text
                            color={"green.500"}
                            fontSize={"sm"}
                            textTransform={"uppercase"}
                            fontWeight={700}
                          >
                            UI UX DESIGN
                          </Text>
                          <Heading
                            fontSize={"2xl"}
                            fontFamily={"body"}
                            fontWeight={700}
                          >
                            DNKL.DSGN
                          </Heading>
                          <Stack direction={"row"} align={"center"}>
                            <Text fontWeight={300} fontSize={"md"}>
                              A Marketing Company which makes companies visible
                              and bring them forward with the help of different
                              marketing measures. They help in generating more
                              sales.
                            </Text>
                          </Stack>
                          <Heading
                          // fontSize={'md'}
                          // mt="5px"
                          // fontFamily={'body'}
                          // fontWeight={500}
                          >
                            {/* See Project → */}
                            <div style={{ display: "flex" }}>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  marginTop: "40px",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Link to="/see-project35">
                                  See project{" "}
                                  <ArrowForwardIcon color="#29A587" />
                                </Link>
                              </Text>
                              <Image
                                display="flex"
                                ml="auto"
                                w="90px"
                                src={dots}
                              />
                            </div>
                          </Heading>
                        </Stack>
                      </Box>
                </>
              )}
             
                 </>
                  
                  )}
              {selectedCategory === "bra" && (
                <>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src={IMAGE}
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        Branding, Photography
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Zorro
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ab animi nihil, dolore totam distinctio
                          reprehenderit,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src={IMAGE}
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        Branding, Photography
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Zorro
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ab animi nihil, dolore totam distinctio
                          reprehenderit,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src={IMAGE}
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        Branding, Photography
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Zorro
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ab animi nihil, dolore totam distinctio
                          reprehenderit,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src={IMAGE}
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        Branding, Photography
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Zorro
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ab animi nihil, dolore totam distinctio
                          reprehenderit,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src={IMAGE}
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        Branding, Photography
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Zorro
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ab animi nihil, dolore totam distinctio
                          reprehenderit,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                  <Box
                    // bgColor="white"
                    bg="white"
                    _dark={{
                      bg: "gray.800",
                    }}
                    mt={10}
                    role={"group"}
                    p={6}
                    maxW={"360px"}
                    w={"full"}
                    // bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={"2xl"}
                    rounded={"lg"}
                    pos={"relative"}
                    zIndex={1}
                  >
                    <Box
                      _hover={{ transform: "scale(1.1)" }}
                      transition="transform 0.3s ease"
                      rounded={"lg"}
                      // mt={-12}
                      pos={"relative"}
                      height={"230px"}
                      _after={{
                        transition: "all .3s ease",
                        content: '""',
                        w: "full",
                        h: "full",
                        // pos: 'absolute',
                        top: 5,
                        left: 0,
                        backgroundImage: `url(${IMAGE})`,
                        filter: "blur(15px)",
                        zIndex: -1,
                      }}
                      _groupHover={{
                        _after: {
                          filter: "blur(20px)",
                        },
                      }}
                    >
                      <Image
                        rounded={"lg"}
                        height={230}
                        width={309}
                        objectFit={"cover"}
                        src={IMAGE}
                      />
                    </Box>
                    <Stack pt={10}>
                      <Text
                        color={"green.500"}
                        fontSize={"sm"}
                        textTransform={"uppercase"}
                        fontWeight={700}
                      >
                        Branding, Photography
                      </Text>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        fontWeight={700}
                      >
                        Zorro
                      </Heading>
                      <Stack direction={"row"} align={"center"}>
                        <Text fontWeight={300} fontSize={"md"}>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ab animi nihil, dolore totam distinctio
                          reprehenderit,
                        </Text>
                      </Stack>
                      <Heading
                      // fontSize={'md'}
                      // mt="5px"
                      // fontFamily={'body'}
                      // fontWeight={500}
                      >
                        {/* See Project → */}
                        <div style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "40px",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Link to="/see-project">
                              See project <ArrowForwardIcon color="#29A587" />
                            </Link>
                          </Text>
                          <Image display="flex" ml="auto" w="90px" src={dots} />
                        </div>
                      </Heading>
                    </Stack>
                  </Box>
                </>
              )}
            </Grid>
            {selectedCategory === "branding" && !showMore && (
              <Flex justifyContent="center" mr={10} mt={4}>
                <Button
                  id="downloadcv"
                  w={{ base: "100%", md: "15%" }}
                  bg=""
                  mt={{ base: 10, md: 0, lg: 5 }}
                  textShadow="0px 2px 2px rgba(255, 255, 255, 0.4)"
                  ml={{ base: "20px" }}
                  fontFamily="Lato, sans-serif"
                  p={7}
                  fontSize="14px"
                  fontWeight="bold"
                  _dark={{
                    border: "2px solid white",
                  }}
                  letterSpacing="1px"
                  transition="0.5s"
                  _hover={{
                    bg: "#29A587",
                    color: "#fff",
                    animation: "ani 0.7s steps(22) forwards",
                  }}
                  borderRadius="30px"
                  boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
                  border="2px solid black"
                  boxSizing="border-box"
                  onClick={handleViewMore}
                >
                  VIEW MORE
                </Button>
              </Flex>
            )}
            {selectedCategory === "ui-ux" && !showMore && (
      <Button
  
      id="downloadcv"
      w={{ base: "100%", md: "15%" }}
      bg=""
      mt={{ base: 10, md: 0, lg: 5 }}
      textShadow="0px 2px 2px rgba(255, 255, 255, 0.4)"
      ml={{ base: "",lg:"470px" }}
      fontFamily="Lato, sans-serif"
      p={7}
      fontSize="14px"
      fontWeight="bold"
      _dark={{
        border: "2px solid white",
      }}
      letterSpacing="1px"
      transition="0.5s"
      _hover={{
        bg: "#29A587",
        color: "#fff",
        animation: "ani 0.7s steps(22) forwards",
      }}
      borderRadius="30px"
      boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
      border="2px solid black"
      boxSizing="border-box"
     
        onClick={toggleShowMore}
      >
        Show more
      </Button>
    )}
     {selectedCategory === "web" && !showMore && (
      <Button
      id="downloadcv"
      w={{ base: "100%", md: "15%" }}
      bg=""
      mt={{ base: 10, md: 0, lg: 5 }}
      textShadow="0px 2px 2px rgba(255, 255, 255, 0.4)"
      ml={{ base: "",lg:"470px" }}
      fontFamily="Lato, sans-serif"
      p={7}
      fontSize="14px"
      fontWeight="bold"
      _dark={{
        border: "2px solid white",
      }}
      letterSpacing="1px"
      transition="0.5s"
      _hover={{
        bg: "#29A587",
        color: "#fff",
        animation: "ani 0.7s steps(22) forwards",
      }}
      borderRadius="30px"
      boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
      border="2px solid black"
      boxSizing="border-box"
     
        onClick={toggleShowMore}
      >
        Show more
      </Button>
    )}
       {selectedCategory === "mobile" && !showMore && (
      <Button
      id="downloadcv"
      w={{ base: "100%", md: "15%" }}
      bg=""
      mt={{ base: 10, md: 0, lg: 5 }}
      textShadow="0px 2px 2px rgba(255, 255, 255, 0.4)"
      ml={{ base: "",lg:"470px" }}
      fontFamily="Lato, sans-serif"
      p={7}
      fontSize="14px"
      fontWeight="bold"
      _dark={{
        border: "2px solid white",
      }}
      letterSpacing="1px"
      transition="0.5s"
      _hover={{
        bg: "#29A587",
        color: "#fff",
        animation: "ani 0.7s steps(22) forwards",
      }}
      borderRadius="30px"
      boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
      border="2px solid black"
      boxSizing="border-box"
        onClick={toggleShowMore}
      >
        Show more
      </Button>
    )}

          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Portfolio;
