import React, { useState } from "react";
import { Box, Container, Flex, useColorModeValue, Grid, Heading, Image, Text, Stack } from "@chakra-ui/react";
import dots from "../wid/dots.png";

const TestimonialBox = ({ imageUrl, text, name, role }) => {
  return (
    <Box
      maxW={{ base: "340px", sm: "270px", lg: "370px" }}
      boxShadow={"2xl"}
      borderRadius="20px"
      overflow={"hidden"}
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
    >
      {imageUrl && (
        <Image
          h={"240px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          w="full"
          borderRadius="40px"
          p={6}
          src={imageUrl}
          objectFit={"cover"}
        />
      )}

      <Box _hover={{ transform: "scale(1.1)" }} transition="transform 0.3s ease" p={6}>
        <Stack spacing={0} mb={5}>
          <Text color={"gray.600"} fontSize={"sm"} textAlign={"start"}>
            {text}
          </Text>
          <div style={{ display: "flex" }}>
            <Text style={{ fontWeight: "700", fontSize: "16px"}} mt="79px">
              {name}
            </Text>
            <Image display="flex" ml="auto" w="100px" src={dots} />
          </div>
          <Text color={"gray.500"} fontSize={"sm"}>
            {role}
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

const Testi = () => {
  const bgColor = useColorModeValue("#f2ede7", "#000");
  const [selectedCategory] = useState("branding");
  const [shuffleKey] = useState(0);

  return (
    <Box id="Testimonials" pt={10} bgColor={bgColor}>
      <Container h="100%" maxW="full">
        <Stack spacing={0} align={"center"}>
          <Heading
            style={{
              letterSpacing: "2px",
              fontWeight: "bolder",
              fontSize: "45px",
              marginTop: "30px",
            }}
          >
            TESTIMONIALS
          </Heading>
          <Text style={{}}>
            <span
              style={{
                color: "#29A587",
                fontFamily: "Falcon Pro",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              WHAT
            </span>{" "}
            <span
              style={{
                fontFamily: "Caveat",
                fontStyle: "cursive",
                fontSize: "30px",
                fontWeight: "bolder",
              }}
            >
              Customers Say
            </span>
          </Text>
        </Stack>
        <Flex
          h="50%"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          w="100%"
          mt={10}
          flex="1"
          display="flex"
        >
          <Box>
            <Grid
              key={shuffleKey}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              }}
              gap={6}
              maxW="1200px"
              mx="auto"
            >
              {selectedCategory === "branding" && (
                <>
                  <TestimonialBox
                    imageUrl=""
                    text="I had the pleasure of working directly with the founder, and I can say without hesitation that he is of one of the most dedicated and hardworking individuals I've ever met. Their hands-on approach and genuine care for his customers."
                    name="Christopher"
                    role="Founder"
                  />
                  <TestimonialBox
                    imageUrl=""
                    text="As a long-time customer, I appreciate the Ashish's responsiveness and hands-on involvement in addressing any concerns or issues and his customer-centric approach is commendable and makes us feel valued as clients."
                    name="Darshan Patel"
                    role="CEO"
                  />
                  <TestimonialBox
                    imageUrl=""
                    text="Working with Ashish was an absolute pleasure. He has an incredible talent for understanding the vision and translating it into stunning designs. The creativity and attention to detail he brings to each project are truly remarkable"
                    name="Roy Wang"
                    role="Designer"
                  />
                </>
              )}

              {/* Portfolio boxes for web category */}
              {selectedCategory === "web" && (
                <>
                  {/* Add responsive portfolio boxes for web category here */}
                </>
              )}

              {/* Add more categories and testimonial boxes as needed */}
            </Grid>
          </Box>
          <Box mt={5}>
            <Grid
              key={shuffleKey}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              }}
              gap={6}
              maxW="1200px"
              mx="auto"
            >
              {selectedCategory === "branding" && (
                <>
                  <TestimonialBox
                    imageUrl=""
                    text="I appreciate the Business Analyst's dedication to understanding our unique business needs. He didn't just provide cookie-cutter solutions but tailored his analysis and recommendations to suit our specific challenges."
                    name="Ali Morshedlou"
                    role="Business Analyst"
                  />
                  <TestimonialBox
                    imageUrl=""
                    text="Our experience with this developer was fantastic. He understood the importance of user experience and built our website with a focus on usability and performance. We have received positive feedback from our customer."
                    name="Juan Encalada"
                    role="Developer"
                  />
                  <TestimonialBox
                    imageUrl=""
                    text="Working with Ashish has been a game-changer for our IT needs. His expertise, commitment, and tailored solutions have not only streamlined our projects but also exceeded our expectations."
                    name="Christina Wocintechchat"
                    role="Project Manager"
                  />
                </>
              )}

            
            </Grid>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Testi;
