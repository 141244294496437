import React from "react";
import {
  Box,
  chakra,
  VStack,
  HStack,
  Icon,
  Text,
  Heading,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FcCheckmark } from "react-icons/fc";
import { Link } from "react-scroll";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const Price = () => {
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("#f2ede7", "#000");

  const Feature = (props) => {
    return (
      <HStack alignItems="start" spacing={2}>
        <Icon
          boxSize={5}
          mt={1}
          mr={2}
          color="#29A587"
          fill="currentColor"
          as={FcCheckmark}
        />
        <chakra.p
          fontSize="lg"
          color={colorMode === "light" ? "gray.600" : "gray.400"}
          {...props}
        />
      </HStack>
    );
  };

  return (
    <chakra.div pt={5} id="Pricing" bg={bgColor}>
      <VStack spacing={6} align="center">
        <Heading
          letterSpacing="2px"
          fontWeight="bold"
          fontSize={{ base: "30px", md: "45px" }}
          marginTop="30px"
        >
          PRICING
        </Heading>
        <Text>
          <chakra.span
            color="#29A587"
            fontFamily="Falcon Pro"
            fontSize="16px"
            fontWeight="bold"
          >
            MY
          </chakra.span>{" "}
          <chakra.span
            fontFamily="Caveat"
            fontStyle="cursive"
            fontSize="30px"
            fontWeight="bold"
          >
            Price Board
          </chakra.span>
        </Text>
      </VStack>
      <HStack
      mt={20}
      spacing={9}
      justify="center"
      align="start"
      flexWrap="wrap"
      maxW="1200px"
      mx="auto"
   
      // h="400px" // Set the desired height here
      >
        <Box
          boxShadow="lg"
          flex="1"
          maxW={{ base: "340px", sm: "270px", lg: "370px" }}
       
          borderRadius="20px"
          bg={colorMode === "light" ? "white" : "gray.700"}
          // h="660px"
          h={{lg:"660px"}}
        >
          <VStack spacing={1} p={8} alignItems="start"  textAlign="start">
            <Text fontWeight="bold" fontSize="xl">
              HOURLY BASIS
            </Text>
            <HStack spacing={3}  mt="30px">
            <Text
                color={colorMode === "light" ? "#29A587" : "white"}
                fontWeight="bold"
                fontSize="2xl"
              >
                $
              </Text>
              <Text fontWeight="bold" fontSize="2xl" textShadow="2px 0 currentcolor">
                39
              </Text>
             
              <Text alignSelf="center" fontWeight="bold">
                /hour
              </Text>
            </HStack>
            <Text mt="20px">Are you looking for top-notch freelancing solutions that bring your projects to life? Look no further! With a proven track record of excellence, I am here to offer you a wide range of skills and services that cater to your unique needs. Whether you're an individual, a startup, or a business, I am dedicated to delivering exceptional results that exceed your expectations</Text>

            <VStack align="start" spacing={2} mt={4}>
              
              <Feature>Website Development</Feature>
              <Feature>Mobile development</Feature>
              <Feature>Consultancy</Feature>
              <Feature>UI/UX</Feature>

              {/* <Feature>Brand Design</Feature>
              <Feature>Web Development</Feature>
              <Feature style={{ textDecoration: 'line-through' }}>Advertising</Feature>
  <Feature style={{ textDecoration: 'line-through' }}>Photography</Feature> */}
            </VStack>
            <Link
  to="Contact" 
  
  smooth={true} 
  duration={500} 
  offset={-100} 
  spy={true} 
  activeClass="active" 
>
            <Button
      mt={9}
      w="120%"
      border="2px solid black"
      // colorScheme="teal"
     bgColor="#29A587"
     color="white"
      rightIcon={<ArrowForwardIcon />}
      size="lg"
      _hover="none"
      borderTopRadius="50px"
      borderBottomRadius="50px"
      fontWeight="bold"
      ml={{base:"5px",lg:"16px"}}
      p="5"
    >
      START PROJECT
    </Button>
    </Link>
          </VStack>
        </Box>

        <Box
  boxShadow="lg"
  flex="1"
  h={{lg:"660px"}}
  maxW={{ base: "340px", sm: "270px", lg: "370px" }}
  border="2px solid black"
  borderRadius="20px"
  bg={colorMode === "light" ? "white" : "gray.700"}
  position="relative"
>
  <Button
    position="absolute"
    top="-15px"
    right="+30px"
bg="#29A587"
color="white"
    size="sm"
    cursor="auto"
    _hover="none"
    border="2px solid black"
    borderTopRadius="50px"
    borderBottomRadius="50px"
    fontWeight="bold"
  >
    POPULAR
  </Button>

  {/* Rest of the content */}
  <VStack spacing={1} p={8} alignItems="start" textAlign="start">
    <Text fontWeight="bold" fontSize="xl">
      FREELANCING
    </Text>
    <HStack spacing={3}  mt="30px">
    <Text
        color={colorMode === "light" ? "#29A587" : "white"}
        fontWeight="bold"
        fontSize="2xl"
       
      >
        $
      </Text>
      <Text    fontWeight="bold" fontSize="2xl" textShadow="2px 0 currentcolor">
        600+
      </Text>
     
      <Text   alignSelf="center" fontWeight="bold">
        /project
      </Text>
    </HStack>
    <Text mt="20px">Harness the expertise of my IT services to supercharge your projects. I provide a wide range of specialized skills, on-demand availability, and personalized solutions tailored to your needs. You'll meet deadlines efficiently while keeping costs in check. Contact me today, and let's make your IT endeavors a resounding success!</Text>

    <VStack align="start" spacing={2} mt={4}>
    <Feature>Website Development</Feature>
              <Feature>Mobile development</Feature>
              <Feature>Consultancy</Feature>
              <Feature>UI/UX</Feature>
              {/* <Feature style={{ textDecoration: 'line-through' }}>Photography</Feature> */}
    </VStack>
    <Link
  to="Contact" 
  
  smooth={true} 
  duration={500} 
  offset={-100} 
  spy={true} 
  activeClass="active" 
>
    <Button
      mt={9}
      w="120%"
      border="2px solid black"
      // colorScheme="teal"
     bgColor="#29A587"
     color="white"
      rightIcon={<ArrowForwardIcon />}
      size="lg"
      _hover="none"
      borderTopRadius="50px"
      borderBottomRadius="50px"
      fontWeight="bold"
      ml={{base:"5px",lg:"16px"}}
      p="5"
    >
      START PROJECT
    </Button>
    </Link>
  </VStack>
        </Box>

        <Box
          boxShadow="lg"
          flex="1"
          h={{lg:"660px"}}
          maxW={{ base: "340px", sm: "270px", lg: "370px" }}
          borderRadius="20px"
          bg={colorMode === "light" ? "white" : "gray.700"}
        >
          <VStack spacing={1} p={8} alignItems="start" textAlign="start">
            <Text fontWeight="bold" fontSize="xl">
              FULL TIME
            </Text>
            <HStack spacing={3}>
            <Text
                color={colorMode === "light" ? "#29A587" : "white"}
                fontWeight="bold"
                fontSize="2xl"
                mt="30px"
              >
                $
              </Text>
              <Text fontWeight="bold" fontSize="2xl" mt="30px" textShadow="2px 0 currentcolor">
                1999
              </Text>
             
              <Text mt="30px" alignSelf="center" fontWeight="bold">
                /month
              </Text>
            </HStack>
            <Text mt="20px">Elevate your IT projects with my full-time freelancing services. My dedication bring a comprehensive skillset, covering various IT domains. I tailor solutions to your unique needs, providing the flexibility to scale your team as required. Say goodbye to traditional hiring complexities and overhead costs while ensuring your projects excel. Contact me today for IT excellence, with your success as my priority!</Text>
            <VStack align="start" spacing={2} mt={4}>
            <Feature>Website Development</Feature>
              <Feature>Mobile development</Feature>
              <Feature>Consultancy</Feature>
              <Feature>UI/UX</Feature>
            {/* <Feature>Brand Design</Feature>
              <Feature>Web Development</Feature>
              <Feature>Advertising</Feature>
              <Feature>Photography</Feature> */}
            </VStack>
            <Link
  to="Contact" 
  
  smooth={true} 
  duration={500} 
  offset={-100} 
  spy={true} 
  activeClass="active" 
>
            <Button
      mt={9}
      w="120%"
      border="2px solid black"
      // colorScheme="teal"
     bgColor="#29A587"
     color="white"
      rightIcon={<ArrowForwardIcon />}
      size="lg"
      _hover="none"
      borderTopRadius="50px"
      borderBottomRadius="50px"
      fontWeight="bold"
      ml={{base:"5px",lg:"16px"}}
      p="5"
    >
      START PROJECT
    </Button>
    </Link>
          </VStack>
        </Box>
      </HStack>
    </chakra.div>
  );
};

export default Price;
