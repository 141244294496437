import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Image,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Flex,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";

import { motion } from "framer-motion";
import ImageWithBlur from "../ImageWithBlur/ImageWithBlur";


const Carousel = () => {
  const items = [
    "https://codekwiks.s3.us-west-2.amazonaws.com/scientia/skin/Group+394.png",
    "https://codekwiks.s3.us-west-2.amazonaws.com/scientia/skin/Group+395.png",
    "https://codekwiks.s3.us-west-2.amazonaws.com/scientia/skin/Group+415.png",
    "https://codekwiks.s3.us-west-2.amazonaws.com/scientia/skin/Group+416.png",
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const bgColor = useColorModeValue("#f2ede7", "#000");
  const [isLoading, setIsLoading] = useState(true);
  const MotionBox = motion(Box);
  let activeInterval; // Variable to store the interval ID
const handleGoBack = () => {
    window.history.back();
  };
  
  useEffect(() => {
    // Simulating image loading with a delay (You should replace this with actual image loading logic)
    const loadingDelay = setTimeout(() => {
      setIsLoading(false);
    }, 7000);

    return () => {
      clearTimeout(loadingDelay);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when the component is mounted
    startSlideshow(); // Start the slideshow

    // Clean up the interval when the component unmounts
    return () => {
      stopSlideshow();
    };
  }, []);

  const startSlideshow = () => {
    // Start the interval for the slideshow
    activeInterval = setInterval(() => {
      handleNext();
    }, 3000);
  };

  const stopSlideshow = () => {
    // Clear the interval when the component unmounts or slideshow stops
    clearInterval(activeInterval);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };
  const buttonProperties = [
    { text: "SHOPIFY" },
    { text: "SHOPIFY APPS" },
    { text: "APIS" },
    { text: "RESPONSIVE DESIGNS" },
    { text: "THIRD PARTY INTEGRATION" },

   
  ];




  const Loader = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Spinner size="xl" color="teal.500" />
        {/* You can adjust the size and color of the spinner as needed */}
      </Box>
    );
  };

  const isItemActive = (index) => index === activeIndex;
  const buttonsPerRow = useBreakpointValue({ base: 1, md: 3 ,lg:9});

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bg={bgColor}
      >
        <MotionBox
          bgColor={bgColor}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9 }}
        >
          <Heading
            style={{
              letterSpacing: "2px",
              fontWeight: "bolder",
              fontSize: "45px",
            }}
            mt="10"
            textAlign="center"
          >
            Project Details
          </Heading>
        </MotionBox>
        <Text>
          <span
            style={{
              color: "#29A587",
              fontFamily: "Falcon Pro",
              fontSize: "16px",
              fontWeight: "bolder",
            }}
          >
            LET'S
          </span>{" "}
          <span
            style={{
              fontFamily: "Caveat",
              fontStyle: "cursive",
              fontSize: "30px",
              fontWeight: "bolder",
            }}
          >
            Talk About Ideas
          </span>
        </Text>  <Box 
        >
        <Button     borderRadius="30px"
              bg=""
              boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              border="2px solid black"
              boxSizing="border-box"
              flexBasis={{ base: "50%", md: "auto" }}
              flexGrow={1}  fontFamily="Lato, sans-serif"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              letterSpacing="1px"
              _dark={{
                border: "2px solid white",
              }}
              transition="0.5s"  _hover={{
                bg: "#29A587",
                color: "#fff",
                animation: "ani 0.7s steps(22) forwards",
              }} mt={{base:"20px"}}   mr={{ lg: "1050px", base: "250px" }} onClick={handleGoBack} variant="outline" colorScheme="teal">
    ◁ BACK
  </Button>
        </Box>
        <Box mt={20}>
          <Heading
            fontWeight={900}
           
   
 mr={{ lg: "890px", base: "" }}
            // ml={{base:"300px",lg:"0"}}
          >
           WAYSLIM & SkIN
          </Heading>
        </Box>

        <Box maxW="100%" mt={10}>
          <ImageWithBlur
            height={{ base: "200px", sm: "300px", md: "400px", lg: "500px" }}
            src="https://codekwiks.s3.us-west-2.amazonaws.com/scientia/skin/Group+396.png"
          />
        </Box>

        <Box
          mt={20}
          mx="auto"
          px={4}
          maxWidth={{ base: "90%", md: "900px" }}
          mr={{ base: "", lg: "440px" }}
        >
          <Heading fontWeight={900}>About project</Heading>
          <Text mt={6}>
          At WaySlim & Skin, we understand that our patients are trusting us to help them look their best. That's why we take pride in the work we do, all under the care of the most devoted and discreet team.

We offer various services designed to help you look and feel your best, from Skin Tightening and Body Contouring, Brazilian Lymphatic Drainage to Laser Lipolysis and Cellulite Reduction.

          </Text>
        </Box>
      
        <Box mt={10} mx="auto" px={4} maxW="1170px"  mr={{lg:"480px"}}>
  <Heading fontWeight={900}>Tech Stacks</Heading>
  
   
      <Flex mt={5} flexWrap="wrap" justifyContent="center" gap={2}>
        {buttonProperties.map((button, index) => (
          <Button
            key={index}
            mt={2}
            fontFamily="Lato, sans-serif"
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="bold"
            letterSpacing="1px"
            _dark={{ border: "2px solid white" }}
            transition="0.5s"
            _hover={{
              bg: "#29A587",
              color: "#fff",
              animation: "ani 0.7s steps(22) forwards",
            }}
            borderRadius="30px"
            flexBasis={{
              base: "80%", // Each button takes 80% width on small screens
              lg:"28%",
              md: `calc(${100 / buttonsPerRow}% - 8px)`, // Each button takes equal width on medium screens (subtract 8px for gap)
            }}
            flexGrow={1}
            bg=""
            boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
            border="2px solid black"
            boxSizing="border-box"
            
            mb={2}
          >
            {button.text}
          </Button>
        ))}
      </Flex>
    </Box>

<Box  mt={20}
  mx="auto"
  px={4}
  maxWidth={{ base: "90%", md: "900px" }}
  mr={{ base: "150px", lg: "1040px" }} >
   
<Heading >Project Preview</Heading>
<a  href="https://www.wayslimandskin.ca/">
<Button
mt={5}
// w={}
 

      fontFamily="Lato, sans-serif"
      fontSize={{ base: "12px", md: "14px" }}
      fontWeight="bold"
      letterSpacing="1px"
      _dark={{
        border: "2px solid white",
      }}
      transition="0.5s"
      _hover={{
        bg: "#29A587",
        color: "#fff",
        animation: "ani 0.7s steps(22) forwards",
      }}
      borderRadius="30px"
      bg=""
      boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
      border="2px solid black"
      boxSizing="border-box"
      flexBasis={{ base: "80%", md: "auto" }}
      flexGrow={1}

     
      mb={2}
    >
      LIVE PREVIEW
    </Button>
</a>

</Box>

        <Heading
          mt={20}
          fontWeight={900}
          sx={{
            letterSpacing: "2px",
          }}
        >
          Visuals
        </Heading>

        <Box
          mt={10}
          bg={bgColor}
          position="relative"
          
          // width={{ lg: "900px", base: "410px" }}
          boxShadow="rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
          // height={{ lg: "60vh", base: "30vh" }}
          p="4"
          overflow="hidden"
        >
            {isLoading ? (
            <Loader />
          ) : (
            items.map((item, index) => (
              <Image
                key={index}
                src={item}
                alt={`Image ${index + 1}`}
                w="100%"
                h="100%"
                opacity={isItemActive(index) ? 1 : 0.1}
             
                position={isItemActive(index) ? "relative" : "absolute"}
                zIndex={isItemActive(index) ? 1 : 0}
                left={isItemActive(index) ? 0 : "-100%"}
                right={isItemActive(index) ? 0 : "-100%"}
              />
            ))
          )}
        </Box>
        <Box mt="2">
          <Button bg="none" onClick={handlePrev} m="2">
            ◁
          </Button>
          <Button bg="none" onClick={handleNext} m="2">
            ▷
          </Button>
        </Box>

        <Box
          width="full"
          borderTop="1px solid #dbdbdb"
          bgColor={bgColor}
          color={useColorModeValue("gray.700", "gray.200")}
        >
          <Stack
            ml={{ base: "20px" }}
            maxW={{ base: "90%", md: "100%" }}
            py={4}
            direction={{ base: "column", md: "row" }}
            spacing={{ base: 4, md: 6 }}
            justify={{ base: "center", md: "space-around" }}
            align={{ base: "center", md: "center" }}
            px={{ base: 4, md: 0 }}
          >
            <Flex fontSize={{ base: "3xl", lg: "2xl" }}>
              <a href="https://www.linkedin.com/">
                <Text>
                  
                </Text>
              </a>
              <a href="https://dribbble.com/">
                <Text ml={6}>
                  
                </Text>
              </a>
              <a href="https://www.behance.net/">
                <Text ml={6}>
                  
                </Text>
              </a>
            </Flex>
            <Text ml={{ base: 0, md: 20 }}>
              <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                © 2023{" "}
              </span>
              <span
                style={{
                  color: "#29A587",
                  fontSize: "16px",
                  fontWeight: "bolder",
                }}
              >
                
              </span>{" "}
              <span style={{ fontSize: "16px" }}>. ALL RIGHTS RESERVED</span>
            </Text>
            <Stack direction={"row"} spacing={{ base: 4, md: 6 }}>
              <Text>
                <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                  DEVELOPED BY
                </span>{" "}
                <span
                  style={{
                    color: "#29A587",
                    fontSize: "16px",
                    fontWeight: "bolder",
                  }}
                >
                  ASHISH MITTAL
                </span>{" "}
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Carousel;
